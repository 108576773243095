<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return{
            rutas: [
                { titulo: 'Pendientes por gestionar', ruta: 'admin.tesoreria.pendientes-gestionar', can: 'tab1_tesoreria_creditos' },
                { titulo: 'Vendedores por liquidar', ruta: 'admin.tesoreria.leecheros-liquidar', can: 'tab1_tesoreria_comisiones' },
                { titulo: 'Comisiones Pagadas', ruta: 'admin.tesoreria.comisiones-pagadas', can: 'tab1_tesoreria_alianza' },
            ]
        }
    },
    mounted(){
        this.rutas[1].titulo = `${this.$config.vendedor} por liquidar`
    }
}
</script>
